<template>

	<div>
		<v-container>
			<v-layout row wrap class="mt-4">
				<v-flex xs12 md4 lg4 xl3 sm12 v-for="(item, index) in option_links" :key="index">
					<v-col>
						<v-card class="ma-3 rounded-lg " outlined :style="{borderColor: item.color_code}" style="border :3px solid" :to=item.path>
							<v-card-title class="px-0" :style="{ color: item.color_code}">
								<div class="ma-4 my-10" style="width:100%;">
									<h1 class="text-center">{{item.title.toUpperCase()}}</h1>
								</div>
							</v-card-title>
						</v-card>
					</v-col>
				</v-flex>
			</v-layout>
		</v-container>
	</div>

</template>
<script>


	export default {
		data() {
			return {


			}
		},
		components: {


		},
		computed: {
			option_links() {
				return [
                    {
                        path: '/item_types-list',
                        title: 'Types',
                        color_code: '#FFC107'
                    },
                    {
                        path: '/item_colors-list',
                        title: 'Colors',
                        color_code: '#65BB64'
                    },
                    {
                        path: '/item_sizes-list',
                        title: 'Sizes',
                        color_code: '#F26B4D'
                    },
 
				]
			}
		},

	}
</script>